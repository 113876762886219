<template>
  <div class="category-form">
    <b-overlay :show="formBusy">
      <portlet :title="portletTitle">
        <template v-slot:body>
          <b-form ref="membership" @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-form-row>
              <b-col sm="3" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
              <b-col sm="3" md="2">
                <b-form-group id="input-group-is_recommend" label="تەۋسىيەلىكمۇ ؟" label-for="is_recommend">
                  <b-form-checkbox id="is_recommend" switch v-model="formData.is_recommend"/>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-8" label="تۈر ماۋزوسى" label-for="title" description="نۆۋەتتىكى ئەزالىق تۈرىنىڭ ماۋزوسىنى كىرگۈزۈڭ">
                  <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="ئەزالىق تۈرىنىڭ ماۋزوسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="title-live-feedback">
                    ئەزالىق تۈرىنىڭ ماۋزوسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-duration" label="ئۈنۈملۈك ۋاقتى" label-for="duration" >
                  <b-input-group prepend="كۈن">
                    <b-form-input style="direction: ltr" min="1" id="duration" v-model="formData.duration" :state="validateState('duration')" aria-describedby="duration-live-feedback" type="number" placeholder="ئەزالىق تۈرىنىڭ ئۈنۈملۈك ۋاقتىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="duration-live-feedback">
                      نۆۋەتتىكى ئەزالىق تۈرىنى ئۈنۈملۈك ۋاقتىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-discount" label="ئېتبارى" label-for="discount" >
                  <b-input-group prepend="%">
                    <b-form-input style="direction: ltr" min="0.1" step="0.1" id="discount" v-model="formData.discount" type="number" placeholder="مەزكۇر ئەزالىقنىڭ ئېتبار نىسبىتىنى كىرگۈزۈڭ" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-price" label="باھاسى" label-for="price" >
                  <b-input-group prepend="￥">
                    <b-form-input style="direction: ltr" min="0.1" step="0.1" id="price" v-model="formData.price" :state="validateState('price')" aria-describedby="price-live-feedback" type="number" placeholder="ئەزالىق تۈرىنىڭ باھاسىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="price-live-feedback">
                      ئەزالىق تۈرىنىڭ تەڭگە باھاسىنى كىرگۈزمىسىڭىز نەق پۇل باھاسىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-score" label="تەڭگە باھاسى" label-for="score" >
                  <b-input-group prepend="دانە">
                    <b-form-input style="direction: ltr" min="0" id="score" v-model="formData.score" :state="validateState('score')" aria-describedby="score-live-feedback" type="number" placeholder="ئەزالىق تۈرىنىڭ تەڭگە باھاسىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="score-live-feedback">
                      نۆۋەتتىكى ئەزالىق تۈرىنىڭ نەق پۇل باھاسىنى كىرگۈزمىسىڭىز تەڭگە باھاسىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort" description="نۆۋەتتىكى تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ">
                  <b-form-input  style="direction: ltr; width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12">
                <b-form-group id="input-group-description" label="چۈشەندۈرلىشى" label-for="description" >
                  <b-form-textarea id="description" v-model="formData.description" type="text" placeholder="ئەزالىق تۈرىنىڭ چۈشەندۈرلىشىنى كىرگۈزۈڭ" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>
<script>
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Portlet from "../../partials/content/Portlet";
  import { validationMixin } from "vuelidate";
  import membershipMixin from "../../../mixins/membershipMixin";
  import { required, requiredIf } from "vuelidate/lib/validators";

  export default {
    name: "categoryForm",
    mixins: [ formBusyMixin, validationMixin, membershipMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    validations(){
      return {
        formData: {
          title: { required },
          duration: { required },
          price: {
            required: requiredIf(()=>{
              return ! this.formData.score;
            })
          },
          score: {
            required: requiredIf(()=>{
              return ! this.formData.price;
            })
          }
        }
      };
    },
    components: { Portlet },
    computed: {
      portletTitle(){
        let create = "يېڭىدىن تۈر قوشۇش";
        let edit = "تۈر ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
    },
    created() {
      if ( this.action === 'edit' ){
        let id = this.$route.params.id;
        this.findMembership(id)
          .then(({data})=>{
            this.formData = data;
          })
          .catch(()=>{
            return this.$router.replace({name: 'membership.list'});
          })
      }
    },
    data(){
      return {
        formBusy: false,
        formData: {
          title: '',
          duration: 1,
          price: null,
          score: null,
          description: '',
          is_show: true,
          is_recommend: false,
          sort: null
        }
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        if ( this.action === 'create' ){
          this.createMembership(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'membership.list'});
            })
        }else if ( this.action === 'edit' ){
          this.updateMembership(this.formData)
            .then(()=>{
              this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'membership.list'});
      }
    }
  }
</script>

<style scoped>

</style>
